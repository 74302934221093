export const successMessages = {
    LOGIN_SUCCESS: "Login Success ! Redirecting..."
};

export const errorMessages = {
    API_NOT_AVAILABLE: "The TOMREX system has some issues while processing your request. Please login again."
};

export const clientRequiredMeassage = {
    CLIENT_PHONE_REQUIRED: "You must have your phone with you at all times at this location."
};