const NotificationType = Object.freeze({
    AGENDA: "Agenda",
    TASK: "Task",
    FINANCIAL: "Financial",
    RESOURCE: "Resource",
    POSITIVE_INCIDENT: "PositiveIncident",
    CLIENT_SURVEY: "Client Survey",
    COMMUNITY_FINANCIAL: "Community Financial",
    MEETINGS: "Meetings",
    CLIENT_ASSESSMENT:"Client Assessment",
    COMMUNITY_FINANCIALS_TRANSACTION: "Community Financials Transaction",
    RESIDENTIAL_FINANCIAL_TRANSACTION: "Residential Financial Transaction",
    SANCTIONS:"Sanctions"
 });

// Export the enums so they can be used in other files
module.exports = {
    NotificationType,
};