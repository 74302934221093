import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dropdown from 'react-bootstrap/Dropdown';
import * as actions from './../../../../store/actions';
import { apiService } from '../../../../services/api.service';
import { history } from "../../../../helpers/history";
import { ReminderModal,AgendaDetailModal,TransactionModal,CommunityFinancialTransactionModal} from '../modal/index';
import { NOTIFICATION_INTERVAL_IN_SECONDS } from '../../../../config/api.config';
import {NotificationType} from '../../../../config/enum.config';

class NotificationDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            modelDetail: {},
            showModal: false,
            modalName: "",
            messageNotificationCount: 0
        }
    }
    close = () => {
        this.setState({ showModal: false, modelDetail: "", modalName: "" });
    }
    getNotifications = () => {
        const { auth } = this.props;
        apiService.post('GETNOTIFICATIONS', { ClientId: auth.user.ClientId, IncludeAck: 0, Offset: "" }).then(response => {
            if (response.Success) {
                this.setState({ notifications: response.Notifications, messageNotificationCount: response.MessageNotificationCount });
            }
        }, error => {
            //  this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    }
    updateNotificationRequest = (ids) => {
        const { auth } = this.props;
        let data = { ids };
        if (auth.user.IsStaffLogin) {
            data.ModifyBy = auth.user.StaffUserName;
        }
        apiService.post("UPDATEACKNOWLEDGES", data).then(
            response => {
                if (response.Success) {
                    this.getNotifications();
                }
            },
            error => {
                this.props.actions.showAlert({ message: error, variant: "error" });
            }
        );
    }

    updateAllNotification = () => {
        let ids = [];
        const { notifications } = this.state;
        if (notifications && notifications.length > 0) {
            notifications.map((item, key) =>
                ids.push(item.Id));
            this.updateNotificationRequest(ids);
        }
    }

    getAgenda = (notificationObject) => {
        let data = {
            LeaveId: notificationObject.KeyId,
            AgendaSource: notificationObject.AgendaSource,
        }
        apiService.post('GETCLIENTLEAVEWITHSCHEDULES', data).then(response => {
            if (response.Success) {
                this.setState({ modelDetail: response, showModal: true, modalName: "Agenda" });
            }
        }, error => {
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    }

    getReminder = (id) => {
        const { auth } = this.props;
        apiService.post('GETCLIENTTASKS', { ClientId: auth.user.ClientId, Id: id }).then(response => {
            if (response.Success && response.ClientTasks) {
                this.setState({ modelDetail: response.ClientTasks[0], showModal: true, modalName: "Reminder" });
            }
        }, error => {
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    }

    getTransactionDetail = (notificationObject) => {
        let API = 'GETCLIENTRESIDENTFINANCIALACCOUNTDETAILS';
        let dataObjName = "ClientResidentFinancialAccountDetails";
        let data = { Id: notificationObject.KeyId, ClientResidentFinancialAccountId: -1 };
        let openModalName = "ResidentialFinancial";
        if (notificationObject.Type === "Community Financials Transaction") {
            API = "GETCLIENTCOMMUNITYFINANCIALACCOUNTDETAILS";
            dataObjName = "ClientCommunityFinancialDetails";
            data = { ClientProgramEnrollmentId: -1, ClientId: -1, ClientCommunityFinancialsId: notificationObject.KeyId };
            openModalName = "CommunityFinancial";
        }
        apiService.post(API, data).then(response => {
            if (response.Success && response[dataObjName]) {
                this.setState({ modelDetail: response[dataObjName][0], showModal: true, modalName: openModalName });
            }
        }, error => {
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    }

//{*********** Optimize the notification function code (remove the all multiple Conditional Statements). ********************* }

    notificationClicked = (notificationObject) => {
        const notificationHandlers = {
            [NotificationType.AGENDA]: () => this.getAgenda(notificationObject),
            [NotificationType.TASK]: () => this.getReminder(notificationObject.KeyId),
            [NotificationType.RESIDENTIAL_FINANCIAL_TRANSACTION]: () => this.getTransactionDetail(notificationObject),
            [NotificationType.COMMUNITY_FINANCIALS_TRANSACTION]: () => this.getTransactionDetail(notificationObject),
            [NotificationType.RESOURCE]: () => history.push('/ResourceLibrary'),
            [NotificationType.POSITIVE_INCIDENT]: () => history.push('/PositiveIncidents'),
            [NotificationType.CLIENT_SURVEY] :() => history.push('/Surveys'),
            [NotificationType.CLIENT_ASSESSMENT]: () => history.push('/Assessments'),
            [NotificationType.MEETINGS]:() => history.push('/Agenda'),
            [NotificationType.SANCTIONS]:() => history.push('/Sanctions')};
            
        if (notificationHandlers[notificationObject.Type]) {
            notificationHandlers[notificationObject.Type]();
        }

    // { ****Update notification request with the notification ID **** }

        let ids = [notificationObject.Id];
        this.updateNotificationRequest(ids);
    }

    componentDidMount() {
        this.interval = setInterval(() => { this.getNotifications() }, 1000 * NOTIFICATION_INTERVAL_IN_SECONDS);
        this.getNotifications()
        history.listen((location, action) => {
            this.setState({ showModal: false });
        });

    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    handleMessageNotificationClick = () => {
        history.push("/Messages");
    }
    render() {
        const { notifications, showModal, modelDetail, modalName, messageNotificationCount } = this.state;
        const { auth } = this.props;    
        return (
            <Fragment>
            {!auth.user.IsTOMREXMessages && (
             <button className='link-button notification-icon' onClick={this.handleMessageNotificationClick}>
             <i className="fa fa-envelope"></i>
             <span className="badge bg-c-pink">{messageNotificationCount}</span>
             </button> )}
                <Dropdown>
                    <Dropdown.Toggle className="notification-icon">
                        <i className="fa fa-bell-o"></i>
                        <span className="badge bg-c-pink">{notifications ? notifications.length : '0'}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="show-notification notification-view">
                        <ul>
                            <li><h6>New Activities </h6><i className="fa fa-check-square-o activeCheck" aria-hidden="true" onClick={() => this.updateAllNotification()}></i></li>
                            {notifications && notifications.map((notification) => {
                              const {Id,NotificationText,Type,FormattedActivityDate} = notification;
                               return(
                                <li key={Id} onClick={() => {
                                    this.notificationClicked(notification)}}>
                                <Dropdown.Item>
                                    <p className={Type === "Meetings" ? '' : 'notification-msg'}>
                                    {Type === "Meetings" ? 'You have a new meeting' : NotificationText}
                                    </p>
                                <span className="notification-time"><i className="fa fa-clock-o" aria-hidden="true">
                                </i> {FormattedActivityDate}</span>
                                </Dropdown.Item>
                                </li>
                                )})}
                                 </ul>
                    </Dropdown.Menu>
                </Dropdown >
                {modalName === "Reminder" && <ReminderModal onClose={this.close} reminder={modelDetail} showModal={showModal} />}
                {modalName === "Agenda" && < AgendaDetailModal onClose={this.close} Agenda={modelDetail} showModal={showModal} />}
                {modalName === "ResidentialFinancial" && <TransactionModal onClose={this.close} transaction={modelDetail} showModal={showModal} showNavigation={true} />}
                {modalName === "CommunityFinancial" && <CommunityFinancialTransactionModal onClose={this.close} transaction={modelDetail} showModal={showModal} showNavigation={true} />}
                {modalName === "Agenda" && <AgendaDetailModal onClose={this.close} Agenda={modelDetail} showModal={showModal} showNavigation={true} />}
            </Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}
const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(
                actions.showAlert,
                dispatch

            )
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDropdown);